import { render, staticRenderFns } from "./EventManagementCard.vue?vue&type=template&id=ba9f2e18&scoped=true&"
import script from "./EventManagementCard.vue?vue&type=script&lang=js&"
export * from "./EventManagementCard.vue?vue&type=script&lang=js&"
import style0 from "./EventManagementCard.vue?vue&type=style&index=0&id=ba9f2e18&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba9f2e18",
  null
  
)

export default component.exports